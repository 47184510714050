import React from 'react';
import { useState } from 'react';
import { payrollManagementContent } from '../../data/payrollContent';


const ManagementBox = ({ icon, width, text,invertIcon, color,changeIndex }) => {
    // const conditionWidth=`md:${width}`
    return (
        <div className={` mt-5 cursor-pointer md:mt-0 rounded-[16px] ${width} p-10 ${color ? "bg-[#985CF1]":"bg-white"}`} onMouseOver={changeIndex} >
            <img src={color?invertIcon:icon} alt="icon" />
            <p className={`mt-6 ${color && "text-white"}`}>{text}</p>
        </div>
    )
}

export const PayrollManagement = () => {
    const [colorIndex, setColorIndex] = useState(0);

  return (
      <section className='h-auto relative'>
          <div className='absolute gradient z-[-1] h-[100%] w-[100%]'></div>
          <div className='w-11/12 mx-auto'>
              <div className='flex flex-col items-center py-10'>
                  <h1 className='font-[700] text-[35px]'><span className='text-[#985CF1]'>{payrollManagementContent.title.coloured}</span>{payrollManagementContent.title.normal}</h1>
                  <p className='mt-5 text-[18px] font-[400]'>{payrollManagementContent.paraLine}</p>
              </div>

              <div className='flex flex-col md:flex-row md:my-5 justify-center md:space-x-5'>
                  {
                      payrollManagementContent.managementFeaturesFirstRow.map((data, index) => {
                          let color = data.index === colorIndex
                          data={...data,color}
                          return <ManagementBox {...data} key={index} changeIndex={()=>setColorIndex(data.index)} />
                      })
                  }
              </div>
              <div className='flex flex-col md:flex-row justify-center md:space-x-5 pb-6'>
                  {
                      payrollManagementContent.managementFeaturesSecondRow.map((data, index) => {
                        let color = data.index === colorIndex
                          data={...data,color}
                          return <ManagementBox {...data} key={index} changeIndex={()=>setColorIndex(data.index)}/>
                      })
                  }
              </div>
        </div>
                
              </section>
  )
}
