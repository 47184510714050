import payrollBanner from "../images/payrollBanner.svg";
import payrollClock from "../images/payrollClock.svg";
import payrollTracking from "../images/payrollTracking.svg";
import payrollLock from "../images/payrollLock.svg";
import payrollTransparency from "../images/payrollTransparency.svg";
import payrollProfile from "../images/payrollProfile.svg";

import payrollTrackingInvert from "../images/payrollTrackingInvert.svg";
import payrollClockInvert from "../images/payrollClockInvert.svg";
import payrollLockInvert from "../images/payrollLockInvert.svg";
import payrollTransparencyInvert from "../images/payrollTransparencyInvert.svg";
import payrollProfileInvert from "../images/payrollProfileInvert.svg";


import payrollCompliance from "../images/payrollCompliance.svg";
import payrollSalary from "../images/payrollSalary.svg";
import payrollCalculation from "../images/payrollCalculation.svg";



export const bannerContent = {
    title: {
        normal: "Error-free and ",
        coloured: "simplified team payment ",
        normalContinuation:"through a technology-driven payroll process"
    },
    paraLine: "Our integrated technical yet simplified approach has helped industries improve workforce team payments to increase organizational and operational efficiency.",
    button: "Book a Demo",
    image:payrollBanner
}

export const payrollManagementContent = {
    title: {
        coloured: "Payroll Management ",
        normal:"system for timely and quick payroll"
    },
    paraLine: "Upgrade to payroll readiness with Spectrawise's payroll management system that automates salary, tax calculations and processes payments without human intervention.",
    managementFeaturesFirstRow: [
        {
            icon: payrollTracking,
            invertIcon:payrollTrackingInvert,
            text: "Manual calculations make the data regarding salaries and benefits error prone",
            width: "md:w-2/5",
            index:0
        },
        {
            icon: payrollTransparency,
            invertIcon:payrollTransparencyInvert,
            text: "Transparency and safety gets compromised due to fragmented access",
            width: "md:w-3/5",
            index:1
        }
    ],
    managementFeaturesSecondRow: [
        {
            icon: payrollLock,
            invertIcon:payrollLockInvert,
            text: "Enjoy hassle free rollout using Spectrawise",
            width: "w-auto 2xl:w-1/3",
            index:2
            
        },
        {
            icon: payrollClock,
            invertIcon:payrollClockInvert,
            text:"Offers simplified salary revisions and benefit plans",
            width: "w-auto 2xl:w-1/3",
            index:3
        },
        {
            icon: payrollProfile,
            invertIcon:payrollProfileInvert,
            text:"Completely transparent and foolproof against data breaches.",
            width: "w-auto 2xl:w-1/3",
            index:4
        }
    ]
}


export const payrollSoftwareContent = {
    title: {
        normal: "Business need nothing less than the ",
        coloured: "best payroll software ",
        normalContinuation:"for transparent payroll services"
    },
    data: [
        {
            title: "Salary Structure Templates",
            dimLine: "Give a professional tinge to your complex salary structure templates",
            listData: [
                "Customize an individual template for every employee based on their position of responsibility",
                "Design templates based on different timelines",
                "Customize the templates to include bonuses and other incentives",
                "Self-serve interfaces enable employees to manage leaves directly and create tax declarations"
            ],
            image:payrollSalary
        },
        {
            title: "Auto-Salary Calculation",
            dimLine: "Use AI to do away with the manual labour of calculations.",
            listData: [
                "After accounting for account leaves and statutory deductions, AI automatically calculates and distributes pay",
                "Net salary to be deposited based on CTC",
                "From registration, payments, and precise return submissions, we handle all",
                "Detects employees with some missing data"
            ],
            image:payrollCalculation
        },
        {
            title: "Compliance Management",
            dimLine: "Hassle-free Compliance management process powered by intuitive UI",
            listData: [
                "Precise return submissions for TDS, PF, PT, LWF and ESI",
                "Ensures transparency and accountability",
                "Follows all statutory rules to process salaries",
                "Auditor-grade compliance programme eliminates the burnout of determining security compliances"
            ],
            image:payrollCompliance
        }
    ]
}