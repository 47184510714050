import React from "react";
import { Layout } from "../components/Layout";
import { PayrollPage } from "../components/Payroll/PayrollPage";
import "../components/index.css";

const Payroll = () => {
    return (
        <Layout>
            <PayrollPage />
        </Layout>
    )
}

export default Payroll;