import React from 'react';
import { bannerContent } from '../../data/payrollContent';
import { useModal } from '../ContactForm';

export const PayrollBanner = () => {
  const { modalAction } = useModal();
  return (
      <section className='bg-[#16072D] lg:h-[600px] 2xl:h-[680px] flex flex-col lg:flex-row lg:items-center py-10 lg:py-4'>
          <div className='md:w-4/5 lg:w-1/2 text-white pl-6 xl:pl-16 space-y-6'>
              <h1 className='text-[35px] lg:text-[48px] font-[700]'>{bannerContent.title.normal}<span className='text-[#985CF1]'>{bannerContent.title.coloured}</span>{bannerContent.title.normalContinuation}</h1>
              <p className='leading-[30px] lg:leading-[40px]'>{bannerContent.paraLine}</p>
              <button className='bg-[#985CF1] text-white py-[11px] px-[25px] rounded-[48px]' onClick={modalAction}>{bannerContent.button}</button>

          </div>
          <img src={bannerContent.image} alt="payrollImage" className='lg:w-1/2'/>
    </section>
  )
}
